<template>
  <div class="root">
    <van-cell-group title="">
      <div class="content">
        <div class="title">
          <span>{{titleB}}</span>
        </div>
        <div v-html="contentB" />
      </div>
    </van-cell-group>
    <van-cell-group title="输入名字等同明白和同意以上所有条款">
      <van-field
        label="签署人"
        label-width="3em"
        maxlength="100"
        clearable
        v-model="form.risk_confirm" input-align="right" placeholder="请输入您的姓名"/>
    </van-cell-group>
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="next" :disabled="!form.risk_confirm.trim()">
        下一步（风险披露）
      </van-button>
      <van-button class="btn" @click="before">
        查看评估结果
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Button, CellGroup, Cell, Field,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted,
  reactive,
  computed,
} from 'vue';
import httpApi from '../../utils/httpApi';
import httpAddApi from '../../utils/httpAddApi';

const titleB = '客户声明';
const contentB = `1. 本人确认就本人所知所及，以上投资风险取向问卷所载的资料为完整、真实及准确（除本人指明不提供资料情况外）。
2. 本人明白及确认如不完整地或不准确地填写以上投资风险取向问卷，本人顾问给予的建议或意见或会不适合本人的需要，并可能导致本人购买不适合本人的产品。本人并且确认有关商讨乃以本人熟悉的语言进行，并无任何人士不当地影响本人作出的决定。
3. 本人确认明白此问卷是为协助评估本人对投资风险的取向而设。经评估之投资风险取向及基于此作出之建议只供参考之用，而不应当作在任何情况下皆准确和完全。本人需自行评估有关风险，确保任何决定是在考虑本人的个别状况和财政位置。若本人不同意该等分析或经评估之投资风险取向或有任何疑问，本人已被告知应与顾问讨论。
4. 本人确认上述经评估之投资风险取向切合本人个别投资风险要求和目标。
`.replace(/\r?\n/g, '<br />');

export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Cell.name]: Cell,
  },
  data() {
    return {
    };
  },
  methods: {
    async next() {
      if (!this.form.risk_confirm) return;
      const params = JSON.parse(JSON.stringify(this.form));
      const httprequest = this.isAddAccount
        ? httpAddApi.updateRiskConfirmInfo : httpApi.postRiskOrientationStepThree;
      const result = await httprequest(params);
      if (result) {
        if (this.isAddAccount) await this.$api.submitLog({ type: 'submitAddRiskConfirmInfo' });
        this.$router.push({ path: '/riskDisclosure/step_one' });
      }
    },
    before() {
      this.$router.back();
    },
  },
  setup() {
    const form = reactive({
      risk_confirm: '',
    });
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    onMounted(async () => {
      store.commit('setTitleValue', '风险认知');
      if (isAddAccount.value) {
        store.commit('setStepsValue', ['风险认知(3/4)', '提交资料']);
        store.commit('setStepsActive', 0);
      } else {
        store.commit('setStepsValue', ['填写资料', '风险认知(3/4)', '提交资料']);
        store.commit('setStepsActive', 1);
      }
      const result = await httpApi.getRiskOrientationStepThree();
      if (!result) return;
      if (result.data.risk_confirm !== undefined) {
        form.risk_confirm = result.data.risk_confirm;
      }
    });
    return {
      isAddAccount,
      titleB,
      contentB,
      form,
    };
  },
};
</script>

<style lang="less">
  .root {
    text-align: left;

    .content {
      margin-top: @space-2;
      padding: @space-2;
      background: @contentColor;
      font-size: @font-size-2;
    }

    .title {
      text-align: center;
    }

    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
